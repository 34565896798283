import {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Consultants from './Screens/Consultants/Consultants';
import CourseDetails from './Screens/Courses/CourseDetails';
import Courses from './Screens/Courses/Courses';
import Lesson from './Screens/Courses/Lesson';
import Consultant from './Screens/Consultants/ConsultantProfile';
import UserCourses from './pages/UserCourses';
import Register from './Screens/Auth/Register';
import Verify from './Screens/Auth/Verify';
import ScrollToTop from './ScrollToTop';
import UserTrackingSystem from './pages/user-tracking-system/intro/UserTrackingSystem';
import './Apps.css';
import UserDashboard from './pages/user-dashboard/UserDashboard';
import TrackingSystems from './pages/user-tracking-system/tracking-systems/TrackingSystems';
import PersonalityAnalysis from './pages/user-personality-analysis/PersonalityAnalysis';
import BankQuestions from './Screens/BankQuestions/BankQuestions';
import Login from './Screens/Auth/Login';
import UserBankQuestions from './pages/user-bank-questions/UserBankQuestions';
import UserPersonalityResult from './pages/user-personality-result/UserPersonalityResult';
import PersonalityTest from './pages/personality-test/PersonalityTest';
import PersonalityTestQuestion from './pages/personality-test-question/PersonalityTestQuestion';
import BankQuestionForm from './pages/bank-question-form/BankQuestionForm';
import Initiatives from './pages/initiatives/Initiatives';
import Initiative from './pages/initiative/intro/Initiative';
import ForgetPassword from './Screens/Auth/ForgetPassword';
import UserSessions from './Screens/Sessions/UserSessions';
import UserTrackingSystems from './Screens/Tracking/UserTrackingSystems';
import RequestConsultation from './Screens/Consultants/RequestConsultation';
import ShowUserProfile from './Screens/Auth/ShowUserProfile';
import ContactUs from './Screens/ContactUs/ContactUs';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import ShoppingCart from './Screens/Cart/ShoppingCart';
import Footer from './components/footer/Footer';
import NavigationHeaderV2 from './components/navigation-header-v2/NavigationHeaderV2';
import Home from './pages/Home/Home';
import Quiz from './Screens/Quiz/Quiz';
import PracticalTaskUplaod from './Screens/Courses/PracticalTaskUplaod';
import LiveCourseDetails from './pages/live-course-details/LiveCourseDetails';
import SignUpEmail from './Screens/Auth/SignUpEmail';
import ResetPassword from './Screens/Auth/ResetPassword';
import Navbar from './components/NavBar';
import ConsultantSessions from './Screens/Booking/ConsultantSessions';
import FreeSession from './Screens/Booking/FreeSession';
import RequestPrivateSession from './Screens/Booking/RequestPrivateSession';
import {getToCart} from './store/CartSlice';
import {useAppDispatch, useAppSelector} from './hooks/hooks';
import Checkout from './Screens/Checkout/Checkout';
import PaymentResult from './Screens/PaymentResult/PaymentResult';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import Survey from './Screens/Survey/Survey';
import ConsultantAvailability from './Screens/Availability/ConsultantAvailability';
import AddAvailability from './Screens/Availability/AddAvailability';
import AddSingleSession from './Screens/Availability/components/AddSingleSession';
import ConsultantBookedSessions from './Screens/Consultants/ConsultantBookedSessions';
import AvailabilityInstructions from './Screens/Consultants/AvailabilityInstructions';
import AddAvailabilitySettings from './Screens/Availability/AddAvailabilitySettings';
import Meet from './meet';
import ChatList from './Screens/Chat/ChatList';
import Chat from './Screens/Chat/Chat';
import TrackingLevels from './Screens/Tracking/TrackingLevels';
//import InitiativeDetailsAfterEnroll from './Screens/Initiative/InitiativeDetailsAfterEnroll';
import MultiUsersEnrolledTrackingSystems from './Screens/Tracking/MultiUsersEnrolledTrackingSystems';
import AssignFamily from './Screens/Tracking/AssignFamily';

import QuizTrack from './Screens/QuizTrack/QuizTrack';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TrackingLevelsList from './Screens/Tracking/TrackingLevelsList';
import Tracks from './Screens/Tracking/Tracks';
import UserInitiatives from './Screens/Initiative/UserInitiatives';
import PackageLevels from './Screens/Initiative/PackageLevels';
import TrackingDetails from './Screens/Initiative/TrackingDetails';
import InitiativeDetailsAfterEnroll from './Screens/Initiative/InitiativeDetailsAfterEnroll';
import UserActvitiyAddProgress from './Screens/Initiative/UserActvitiyAddProgress';
//import UserTrackings from './Screens/Trackings/UserTrackings';
import UserTrackings from './Screens/Initiative/UserTrackings';
import TrackingPackageLevels from './Screens/Initiative/TrackingPackageLevels';
//import TrackingDetails from './Screens/Trackings/TrackingDetails';
import UserTrackingActvitiyAddProgress from './Screens/Trackings/UserTrackingActvitiyAddProgress';
import PrivateSessionsList from './Screens/Booking/PrivateSessionsList';
import PrivateSessionDetails from './Screens/Booking/PrivateSessionDetails';
import Teachers from './Screens/Consultants/Teachers';
import ConsultantProfile from './Screens/ConsultantProfile/ConsultantProfile';
import ConsultantPages from './Screens/ConsultantPages/ConsultantPages';
import ProtectedRoute from './ProtectedRoute';
import ComeSoon from './components/coming-soon/ComeSoon';
import UserSurveyList from './Screens/Survey/UserSurveyList';
import SurveyMeeting from './Screens/SurveyMeeting';
import LoggedInRoute from './LoggedInRoute';
import { getUserData } from './Screens/Auth/Auth.service';
import { isUserLoggedIn } from './auth/utiles';
import { setLoading } from './store/userSlice';
import ChildForm from './Screens/Auth/ChildForm';
import UserTaskAddProgress from './Screens/Initiative/UserTaskAddProgress';
import PrivateTrackingRequest from './Screens/Initiative/PrivateTrackingRequest';
import TrackingDetailsAfterEnroll from './Screens/Initiative/TrackingDetailsAfterEnroll';
import EventsList from './Screens/Events/EventsList';
import EventDetails from './Screens/Events/EventDetails';
import NotFound from './Screens/NotFound';
import NotAvailable from './Screens/NotAvailable';

function App() {
  
  

  const Logged = isUserLoggedIn();
  if( !Logged ){
    const normalRoutes = ['/' , '/login' , '/sign-up' , '/register' , '/forget-password' , ' /reset-password' , '/verify' , '/show/user/profile'  ]
    if( !normalRoutes.includes(window.location.pathname ) ){
      localStorage.setItem('distenationRoute' , window.location.pathname )
    }
  }
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [navVisible, showNavbar] = useState(false);
  dispatch(getToCart());
  const Loading = useAppSelector(state => state.user.loading);
  console.log('LoadingLoadingLoading', Loading);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getUserData();
    console.log('result.response', result.response);
    if (result.response.status) {
        dispatch(setLoading(false));
        localStorage.setItem('isCons', result.response.user.is_consultant);
    }
  };

  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  
  const type = urlParams.get('type')
  const modelId = urlParams.get('model_id')
  const invitationId = urlParams.get('invitation_id')
  const invitation_user_id = urlParams.get('invitation_user_id')
  

  const getDynamicLinkParams = () => {
    if( Logged && localStorage.getItem('isCons') == 'true' && localStorage.getItem('switch') == '1' ){
      navigate('/consultants/availablilty')
    }
    if(  modelId  && Logged ){

      if( type == 'Initiative' ){
        let url = `/user/trackings/${modelId}/details?is_level=0&type=Initiative&model_id=${modelId}&invitation_id=${invitationId}`
        url += invitation_user_id ? `&invitation_user_id=${invitation_user_id}` : ''  
        navigate(url)
      }else if( type == 'InitiativePackage' ){
        let url = `/user/initiatives/${modelId}/package/levels?discover=1&type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`
        url += invitation_user_id ? `&invitation_user_id=${invitation_user_id}` : '' 
        navigate(`/user/initiatives/${modelId}/package/levels?discover=1&type=InitiativePackage&model_id=${modelId}&invitation_id=${invitationId}`)
      }else if( type == 'Event' ){
        navigate(`/event/${modelId}/details`)
      }else if( type == 'tracking' ){ 
        navigate(`/user/trackings/${modelId}/details?is_level=0`)
      }else if( type == 'trackingPackage' ){ 
        navigate(`/user/trackings/${modelId}/package/levels?discover=1`)
      }else if( type == 'Course' ){ 
        navigate(`/courses/${modelId}`)
      }else if( type == 'Consultant' ){ 
        navigate(`/consultants/consultant/${modelId}`)
      }

      

    }else{

      if( !Logged ){
        

        if( type == 'referralCode' ){
          localStorage.setItem('userRefCode' , modelId )
        }

        if( modelId ){
          let url = `/login?type=${type}&model_id=${modelId}`
          url += ( type == 'Initiative' || type == 'InitiativePackage' ) ? `&invitation_id=${invitationId}` : ''
          navigate(url)
        }else{

        }
      }
      
    }

    if( type == 'InitiativesList' ){
      navigate('/user/initiatives/')
    }else if( type == 'FollowUpSystemsList' ){
      navigate('/user/trackings/list/')
    }else if( type == 'FollowUpSystemsList' ){
      navigate('/consultants/get-teachers')
    }

    
    
    
  };

  useEffect(() => {
    if(Logged){
      getData()
    }

    getDynamicLinkParams()
  }, []);



  return (
    <div>
      <div className="">
        {Loading && (
          <div className="LoaderDiv">
            <LoadingSpinner />
          </div>
        )}
        {/*<NavigationHeader />*/}
      </div>
      <NavigationHeaderV2 />

      {/*<Navbar visible={navVisible} show={showNavbar} />*/}
      {/* <UserNavigationHeader /> */}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/meet/:authToken" element={<Meet />} />

        <Route path="/sign-up" element={<SignUpEmail />} />

        <Route path="/register" element={<Register />} />

        <Route path="/forget-password" element={<ForgetPassword />} />

        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/verify" element={<Verify />} />

        <Route path="/contact-us" element={<LoggedInRoute><ContactUs /></LoggedInRoute>} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="/" element={<Home />} />

        <Route path="/courses/*" element={<LoggedInRoute><Courses /></LoggedInRoute>} />
        <Route path="/courses/:id/*" element={<LoggedInRoute><CourseDetails /></LoggedInRoute>} />

        <Route path="/live/courses/:id" element={<LoggedInRoute><LiveCourseDetails /></LoggedInRoute>} />

        <Route path="/lessons/:id" element={<LoggedInRoute><Lesson /></LoggedInRoute>} />

        <Route path="/quiz/:id" element={<LoggedInRoute><Quiz /></LoggedInRoute>} />

        <Route
          path="/practical-task-upload"
          element={<LoggedInRoute><PracticalTaskUplaod /></LoggedInRoute>}
        />

        <Route path="/consultants/consultants/*" element={<LoggedInRoute><Consultants /></LoggedInRoute>} />
        <Route
          path="/consultants/get-consultants/*"
          element={ <LoggedInRoute> <Consultants /></LoggedInRoute>}
        />
        <Route path="/consultants/get-teachers/*" element={<LoggedInRoute><Teachers /></LoggedInRoute>} />
        <Route path="/consultants/:type/:id/*" element={<LoggedInRoute><Consultant /></LoggedInRoute>} />

        <Route path="/show/user/profile/*" element={<LoggedInRoute><ShowUserProfile /></LoggedInRoute>} />
        <Route path="/user/courses/*" element={<UserCourses />} />
        <Route path="/user/dashboard" element={<LoggedInRoute><UserDashboard /></LoggedInRoute>} />

        <Route path="/user/tracking-systems" element={<LoggedInRoute><TrackingSystems /></LoggedInRoute>} />

        <Route
          path="/user/tracking-system/*"
          element={<LoggedInRoute><UserTrackingSystems /></LoggedInRoute>}
        />
        <Route
          path="/user/tracking-system/levels/*"
          element={<LoggedInRoute><TrackingLevelsList /></LoggedInRoute>}
        />
        <Route
          path="/user/tracking-system/single/list/*"
          element={<LoggedInRoute><Tracks /></LoggedInRoute>}
        />

        <Route
          path="/user/personality-analysis"
          element={<LoggedInRoute><PersonalityAnalysis /></LoggedInRoute>}
        />

        <Route
          path="/user/personality/result"
          element={<LoggedInRoute><UserPersonalityResult /></LoggedInRoute>}
        />
        <Route
          path="/user/tracking-system/intro/:id/*"
          element={<LoggedInRoute><UserTrackingSystem /></LoggedInRoute>}
        />

        <Route
          path="/consultants/sessions/:id/*"
          element={<LoggedInRoute><ConsultantSessions /></LoggedInRoute>}
        />
        <Route path="/consultants/sessions/free" element={<FreeSession />} />

        <Route
          path="/consultants/request/private/session/:id/*"
          element={<LoggedInRoute><RequestPrivateSession /></LoggedInRoute>}
        />

        <Route path="/user/sessions/*" element={<LoggedInRoute><UserSessions /></LoggedInRoute>} />

        <Route
          path="/user/consultation/request/*"
          element={<LoggedInRoute><RequestConsultation /></LoggedInRoute>}
        />

        <Route path="/bank-questions/*" element={<LoggedInRoute><BankQuestions /></LoggedInRoute>} />

        <Route path="/bank-question/form/*" element={<LoggedInRoute><BankQuestionForm /></LoggedInRoute>} />

        <Route path="/my/bankQuestions" element={<LoggedInRoute><UserBankQuestions /></LoggedInRoute>} />

        <Route path="/persinality/test/*" element={<LoggedInRoute><PersonalityTest /></LoggedInRoute>} />

        <Route
          path="/persinality/test/question/*"
          element={<LoggedInRoute><PersonalityTestQuestion /></LoggedInRoute>}
        />

        <Route path="/initiatives/*" element={<LoggedInRoute><Initiatives /></LoggedInRoute>} />

        <Route path="/initiative/intro/*" element={<LoggedInRoute><Initiative /></LoggedInRoute>} />

        <Route path="/cart" element={<LoggedInRoute><ShoppingCart /></LoggedInRoute>} />

        <Route path="/checkout" element={<LoggedInRoute><Checkout /></LoggedInRoute>} />
        <Route path="/payment-result" element={<LoggedInRoute><PaymentResult /></LoggedInRoute>} />

        <Route path="/survey/:id/*" element={<LoggedInRoute><Survey /></LoggedInRoute>} />

        <Route path="/user/survey/list/*" element={<LoggedInRoute><UserSurveyList /></LoggedInRoute>} />

        <Route
          path="/consultants/availablilty"
          element={
            <ProtectedRoute>
              <ConsultantAvailability addHeaderOptions={true} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/consultants/add/availablilty/:id/*"
          element={<LoggedInRoute><AddAvailability /></LoggedInRoute>}
        />

        <Route
          path="/consultants/add/single/session/:id/*"
          element={<LoggedInRoute><AddSingleSession /></LoggedInRoute>}
        />
        <Route
          path="/consultants/add/single/session/:id/*"
          element={<LoggedInRoute><AddSingleSession /></LoggedInRoute>}
        />

        <Route
          path="/consultant/booked-sessions/*"
          element={
            <ProtectedRoute>
              <ConsultantBookedSessions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/consultant/add-availability-settings/*"
          element={<LoggedInRoute><AddAvailabilitySettings /></LoggedInRoute>}
        />

        <Route
          path="/consultants/add/availablilty/:id/*"
          element={<LoggedInRoute><AddAvailability /></LoggedInRoute>}
        />
        <Route
          path="/consultants/add/single/session/:id/*"
          element={<LoggedInRoute><AddSingleSession /></LoggedInRoute>}
        />

        <Route
          path="/consultants/availablity/instructions/*"
          element={<LoggedInRoute><AvailabilityInstructions /></LoggedInRoute>}
        />
        <Route path="/chatlist" element={<LoggedInRoute><ChatList /></LoggedInRoute>} />
        <Route path="/chat/:userId/:guestId" element={<LoggedInRoute><Chat /></LoggedInRoute>} />

        <Route
          path="/tracking-system/:id/levels/*"
          element={<LoggedInRoute><TrackingLevels /></LoggedInRoute>}
        />

       {/*
        <Route
          path="/tracking-system/:id/details/*"
          element={<LoggedInRoute><TrackingDetailsAfterEnroll /></LoggedInRoute>}
        />
        */}

        <Route
          path="/multi-users/enrolled/tracking-system/:id/*"
          element={<MultiUsersEnrolledTrackingSystems />}
        />

        <Route
          path="/tracking-system/:id/assign-family/*"
          element={<LoggedInRoute><AssignFamily /></LoggedInRoute>}
        />

        <Route
          path="/tracking/:id/:userId/:username/private-request/*"
          element={<LoggedInRoute><PrivateTrackingRequest /></LoggedInRoute>}
        />

        <Route path="/tracking-system-quiz/*" element={<LoggedInRoute><QuizTrack /></LoggedInRoute>} />

        <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />

        <Route path="/user/initiatives/*" element={<LoggedInRoute><UserInitiatives /></LoggedInRoute>} />

        <Route path="/user/trackings/list/*" element={<LoggedInRoute><UserTrackings /></LoggedInRoute>} />

        <Route
          path="/user/initiatives/:id/package/levels/*"
          element={<LoggedInRoute><PackageLevels /></LoggedInRoute>}
        />

        <Route
          path="/user/trackings/:id/package/levels/*"
          element={<LoggedInRoute><TrackingPackageLevels /></LoggedInRoute>}
        />

        <Route
          path="/user/trackings/:id/details"
          element={<LoggedInRoute><TrackingDetails /></LoggedInRoute>}
        />

        <Route
          path="/user/initiative/:id/details/enrolled/:userId/*"
          element={<LoggedInRoute><InitiativeDetailsAfterEnroll /></LoggedInRoute>}
        />

        {/*    
        <Route
          path="/user/trackings/:id/details/enrolled/:userId/*"
          element={<LoggedInRoute><TrackingDetailsAfterEnroll /></LoggedInRoute>}
        />
        */}

        <Route
          path="/activity/:actId/:trackId/:date/:userId/:daySelected/add-progress/*"
          element={<LoggedInRoute><UserActvitiyAddProgress /></LoggedInRoute>}
        />

        {/*<Route path="/user/trackings/*" element={<LoggedInRoute><UserTrackings /></LoggedInRoute>} />*/}

        <Route
          path="/user/tracking-packages/:id/package/levels/*"
          element={<LoggedInRoute><TrackingPackageLevels /></LoggedInRoute>}
        />

        <Route
          path="/user/tracking/:id/details/*"
          element={<LoggedInRoute><TrackingDetails /></LoggedInRoute>}
        />

        <Route
          path="/user/initiatives/:id/details/enrolled/:userId/*"
          element={<LoggedInRoute><InitiativeDetailsAfterEnroll /></LoggedInRoute>}
        />

        <Route
          path="/user/trackings/:id/details/enrolled/:userId/*"
          element={<LoggedInRoute><TrackingDetailsAfterEnroll /></LoggedInRoute>}
        />

        <Route
          path="/private/sessions/list/*"
          element={<LoggedInRoute><PrivateSessionsList /></LoggedInRoute>}
        />

        <Route
          path="/private/sessions/:id/details/:isCons/*"
          element={<LoggedInRoute><PrivateSessionDetails /></LoggedInRoute>}
        />

        <Route
          path="/consultant/profile/*"
          element={
            <ProtectedRoute>
              <ConsultantPages />
            </ProtectedRoute>
          }
        />

        <Route path="/coming-soon/*" element={<ComeSoon />} />

        <Route
          path="/survey/meeting/:id/*"
          element={<LoggedInRoute><SurveyMeeting /></LoggedInRoute>}
        />

        <Route
          path="/add-child/*"
          element={<LoggedInRoute><ChildForm /></LoggedInRoute>}
        />

        <Route
          path="/task-details/:id/:memberId/*"
          element={<LoggedInRoute><UserTaskAddProgress /></LoggedInRoute>}
        />

        <Route
          path="/events-list/*"
          element={<LoggedInRoute><EventsList /></LoggedInRoute>}
        />

        <Route
          path="/event/:id/details/*"
          element={<LoggedInRoute><EventDetails /></LoggedInRoute>}
        />

        <Route path="/not-found/*" element={<NotFound />} />

        <Route path="/not-available/*" element={<NotAvailable />} />

      </Routes>

      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
